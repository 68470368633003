<template>
    <div class="page-stories">
        <div class="story-title">{{ title }}</div>

        <div class="story-list">
            <div class="item" v-for="(item, index) in list.data" :key="index">
                <div class="thumb">
                    <img :src="item.thumb" />
                </div>
                <div class="text-container">
                    <router-link :to="`/article/detail/${item.id}`">
                        <h2>{{ item.title }}</h2>
                    </router-link>
                    <div class="date">
                        <calendar-dot class="icon" theme="filled" size="14" fill="#999" :strokeWidth="3"/>
                        <span class="text">{{ (item.published_at).substring(0,10) }}</span>
                        <eyes class="icon" theme="filled" size="16" fill="#999" :strokeWidth="3"/>
                        <span class="text">{{ item.visits }}</span>
                    </div>
                    <p>
                        {{ item.description }}
                    </p>
                    <div class="explore">
                        <router-link :to="`/article/detail/${item.id}`">— Explore</router-link>
                    </div>
                </div>
            </div>
        </div>

        <!-- 翻页 -->
        <pagination 
            v-model:list="list"
        ></pagination>

    </div>

    <div class="page-bg">
        <img class="bg" src="@/assets/images/product-banner.png" />
    </div>
</template>

<script>
import { CalendarDot, Eyes, Left, Right } from '@icon-park/vue-next'
import Pagination from '@/components/Pagination.vue'
export default {
    name: 'PageStories',
    components: {
        CalendarDot, Eyes, Left, Right,
        Pagination
    },
    data(){
        return {
            list: {},
            title: 'Articles'
        }
    },
    watch: {
        $route(){
            this.getList(this.$route.params.id);
            this.getCategory(this.$route.params.id);
        }
    },
    mounted(){
        this.getList(this.$route.params.id);
        this.getCategory(this.$route.params.id);
    },
    methods:{
        getList(id){
            this.$api.articles(id).then(res => {
                this.list = res.data;
            })
        },
        getCategory(cat_id){
            this.$api.category(cat_id).then(res => {
                this.title = res.data.title
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page-stories{
    @extend .flex-column;
    position: relative;
    z-index: 2;
    .story-title{
        @extend .flex-column-center;
        height: 300px;
        font-family: 'cusTitle';
        font-size: 48px;
        color: $orange;
    }
    .story-list{
        @extend .flex-column;
        width: 1200px;
        margin: -30px auto 0;
        .item{
            @extend .flex-row;
            padding: 10px;
            margin-bottom: 20px;
            border-radius: $radius-big;
            background-color: $white;
            box-shadow: $shadow-default;
            &:hover{
                box-shadow: $shadow;
                .thumb img{
                    height: 110%;
                    margin: -5% 0 0 -5%;
                }
            }
            .thumb{
                width: 220px;
                height: 220px;
                border-radius: $radius-big;
                overflow: hidden;
                img{
                    // max-width: 100%;
                    height: 100%;
                }
            }
            .text-container{
                @extend .flex-column;
                width: 940px;
                margin-left: 20px;
                h2{
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 10px 0;
                    font-family: 'cusTitle';
                    color: $text-color;
                    &:hover{
                        color: $orange;
                    }
                }
                .date{
                    @extend .flex-row;
                    font-size: 14px;
                    color: $info;
                    .icon{
                        height: 16px;
                    }
                    .text{
                        margin: 0 15px 0 3px;
                    }
                }
                p{
                    margin: 20px 0;
                    line-height: 32px;
                    font-size: 14px;
                    color: $info;
                }
                .explore{
                    @extend .flex-row;
                    font-size: 14px;
                    a{
                        text-align: center;
                        padding: 10px 0;
                        color: $orange;
                        &:hover{
                            padding: 10px 20px;
                            border-radius: $radius-big;
                            background-color: $orange;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
    .pages{
        margin-bottom: 50px;
    }
}
</style>